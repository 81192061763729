// *************************************************************************************************
// =================================================================================================

$layout-page__header__height: 64px;

$layout-page__header__title__font-size: 24px;
$layout-page__header__title__line-height: 40px;

$layout-page__scrollbar__width: 8px;
$layout-page__scrollbar-track__box-shadow-width: 2px;

// =================================================================================================
// *************************************************************************************************
