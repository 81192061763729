.apixmed-dark {

	// *********************************************************************************************
	// =============================================================================================
	// Shared
	// =============================================================================================

	--gdpr-panel__background: rgba(121, 121, 121, 0.9);
	--gdpr-panel__text-color: rgba(255, 255, 255, 1);
	--gdpr-panel__btn-accept__background: rgba(255, 255, 255, 1);
	--gdpr-panel__btn-accept__text-color: #212121;

	--tag__chip__disease__background:#D81B60;
	--tag__chip__disease__text-color:#fff;
	--tag__chip__medication__background:#1976D2;
	--tag__chip__medication__text-color:#fff;
	--tag__chip__symptom__background:#AD1457;
	--tag__chip__symptom__text-color:#fff;
	--tag__chip__doctor-encounter__background: #6200EA;
	--tag__chip__doctor-encounter__text-color: #fff;
	--tag__chip__document__background: #455A64;
	--tag__chip__document__text-color: #fff;
	--tag__chip__health-issue__background: #880E4F;
	--tag__chip__health-issue__text-color: #fff;
	--tag__chip__taken-medication__background: #0D47A1;
	--tag__chip__taken-medication__text-color: #fff;
	--tag__chip__serious-disease__disabled__background: #BDBDBD;
	--tag__chip__serious-disease__disabled__text-color: #212121;

	--form-anamnesis__icon__text-color: #424242;

	--gif__treatment-checker__icon: url('/assets/apixmed-dark/shared/genetic-engineering.gif');

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Vendors
	// =============================================================================================

	--vendor__facebook__btn__background: rgb(26, 119, 242);
	--vendor__facebook__btn__text-color: rgb(255, 255, 255);

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Accounts
	// =============================================================================================

	--accounts-layout__scrollbar__background: #fafefe;
	--accounts-layout__scrollbar-thumb__background: #212121;
	--accounts-layout__scrollbar-track__background: #fafefe;
	--accounts-layout__scrollbar-track__box-shadow__color: rgba(0, 0, 0, 0.3);

	--accounts-layout__greeting-container__background: none;
	--accounts-layout__greeting-title__text-color: #fafefe;
	--accounts-layout__greeting-description__text-color: #fafefe;

	--accounts-layout__main-container__background: none;

	--accounts-layout__main-card__container__background: #424242;
	--accounts-layout__main-card__container__text-color: #fafefe;
	--accounts-layout__main-card__submit-btn__background: rgba(0, 150, 136, 1);
	--accounts-layout__main-card__submit-btn__text-color: #fafefe;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Genetic card
	// =============================================================================================

	--genetic-tests__card__btn-create__background: #009688;
	--genetic-tests__card__btn-create__text-color: #ffffff;

	--genetic-tests__card__btn-info__background: #212121;
	--genetic-tests__card__btn-info__text-color: #fafefe;

	--genetic-tests__card__genetic-file-upload__btn-navigate-genetic-card__background:#009688;
	--genetic-tests__card__genetic-file-upload__btn-navigate-genetic-card__text-color:#ffffff;

	--genetic-tests__card__genetic-file-upload__btn-initiate-new-upload__background: #009688;
	--genetic-tests__card__genetic-file-upload__btn-initiate-new-upload__text-color: #ffffff;

	--genetic-tests__info__btn-create__background: #009688;
	--genetic-tests__info__btn-create__text-color: #ffffff;

	--genetic-tests__info__btn-card__background: #212121;
	--genetic-tests__info__btn-card__text-color: #fafefe;


	--genetic-tests__creation-type-default__background-color: #00BFA5;
	--genetic-tests__creation-type-imported__background-color: #274983;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Home page B2C
	// =============================================================================================

	--home-page-b2c__img__logo: url('/assets/apixmed-dark/branding/dna-logo.svg');

	--home-page-b2c__scrollbar__background: rgba(255, 255, 255, 0.5);
	--home-page-b2c__scrollbar-thumb__background: rgba(117, 117, 117, 1);
	--home-page-b2c__scrollbar-track__background: rgba(66, 66, 66, 1);
	--home-page-b2c__scrollbar-track__border-color: rgba(117, 117, 117, 1);

	--home-page-b2c__header__background: rgba(24, 24, 26, 0.2);
	--home-page-b2c__header__text-color: rgba(255, 255, 255, 1);

	--home-page-b2c__body__background: #212121;
	--home-page-b2c__body__text-color: rgba(255, 255, 255, 1);

	--home-page-b2c__footer__background: #212121;
	--home-page-b2c__footer__text-color: rgba(255, 255, 255, 1);
	--home-page-b2c__footer__social-btn-icon__color: rgba(255, 255, 255, 1);

	--home-page-b2c__text-highlighted__color: rgba(51, 173, 173, 1);
	--home-page-b2c__border__color: rgba(89, 156, 171, 0.2);

	--home-page-b2c__img__dna: url('/assets/apixmed-dark/modules/home/dna.svg');

	--home-page-b2c__medical-card-btn__background: linear-gradient(90deg, rgba(0, 121, 107, 1), rgba(0, 105, 92, 1));
	--home-page-b2c__medical-card-btn__text__color: rgba(250, 250, 250, 1);
	--home-page-b2c__img__medical-grid: url('/assets/apixmed-dark/modules/home/medical-grid.svg');

	--home-page-b2c__treatment-checker-btn__background: linear-gradient(90deg, rgba(255, 78, 74, 1), rgba(255, 58, 106, 1));
	--home-page-b2c__treatment-checker-btn__text-color: rgba(250, 250, 250, 1);
	--home-page-b2c__treatment-checker__stepper__node1__color: rgba(0, 191, 255, 1);
	--home-page-b2c__treatment-checker__stepper__node2__color: rgba(0, 255, 229, 1);
	--home-page-b2c__treatment-checker__stepper__node3__color: rgba(16, 255, 159, 1);
	--home-page-b2c__treatment-checker__stepper__node4__color: rgba(119, 255, 0, 1);
	--home-page-b2c__img__treatment-checker__transition-1-to-2: url('/assets/apixmed-dark/modules/home/treatment-checker-treatment.png');
	--home-page-b2c__img__treatment-checker__transition-2-to-3: url('/assets/apixmed-dark/modules/home/treatment-checker-lifestyle.png');
	--home-page-b2c__img__treatment-checker__transition-3-to-4: url('/assets/apixmed-dark/modules/home/treatment-checker-anamnesis.png');

	--home-page-b2c__img__microsoft-logo: url('/assets/apixmed-dark/modules/home/microsoft.svg');
	--home-page-b2c__img__eit-logo: url('/assets/apixmed-dark/modules/home/eit.png');
	--home-page-b2c__img__intersystems-logo: url('/assets/apixmed-dark/modules/home/intersystems.svg');
	--home-page-b2c__img__portmone-label: url('/assets/apixmed-dark/modules/home/portmone-label.svg');

	--home-page-b2c__app-info__text-color: #9E9E9E;

	--home-page-b2c__side-panel-left__btn__color: rgba(255, 255, 255, 1);
	--home-page-b2c__side-panel-right__btn__color: rgba(255, 255, 255, 1);

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Pricing
	// =============================================================================================

	--pricing__plan-feature__available__icon__color: #00BFA5;
	--pricing__plan-feature__available__text-color: #00BFA5;
	--pricing__plan-feature__not-available__icon__color: #BDBDBD;
	--pricing__plan-feature__not-available__text-color: #BDBDBD;

	--pricing__plan__container__background-color: #424242;
	--pricing__plan__container__text-color: #fefefe;

	--pricing__plan__premium__header__text-color: #00BFA5;
	--pricing__plan__basic__header__text-color: #F5F5F5;

	--pricing__payment-plan__general__background: #636363;
	--pricing__payment-plan__general__text-color: #FAFAFA;
	--pricing__payment-plan__non-highlight__color: #E0E0E0;

	--pricing__payment-plan__center__border-vertical: thick solid #E0E0E0;

	--pricing__payment-plan__btn-annual__background: #00796B;
	--pricing__payment-plan__btn-annual__text-color: #FFFFFF;

	--pricing__payment-plan__btn-semiannual__background: #009688;
	--pricing__payment-plan__btn-semiannual__text-color: #FFFFFF;

	--pricing__payment-plan__btn-monthly__background: #00897B;
	--pricing__payment-plan__btn-monthly__text-color: #FFFFFF;

	--pricing__payment-plan__btn-corporate__background: #FFFFFF;
	--pricing__payment-plan__btn-corporate__text-color: #005570;

	--pricing__plan__premium__set-dialog__btn-confirm__background: #FFFFFF;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Privacy policy
	// =============================================================================================

	--privacy-policy__container__background: #424242;
	--privacy-policy__text-color: #fefefe;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Treatment checker
	// =============================================================================================

	--treatment-checker__patient__form__description__icon__color: #2196F3;
	--treatment-checker__patient__form__redirect__icon__color: #ffc107;

	--treatment-checker__home__to-form__btn__background: rgba(0, 150, 136, 1);
	--treatment-checker__home__to-form__btn__text-color: #fafefe;

	--treatment-checker__btn-analyze__background: rgba(0, 150, 136, 1);
	--treatment-checker__btn-analyze__text-color: #fafefe;

	--treatment-checker__btn-export__background: #424242;
	--treatment-checker__btn-export__text-color: #fafefe;

	--treatment-checker__container__background: #292929;
	--treatment-checker__container__text-color: #fefefe;

	--treatment-checker__card__background: #424242;
	--treatment-checker__card__text-color: #fefefe;

	--treatment-checker__result__section-title__text-color: #fefefe;
	--treatment-checker__result__icon__color: #00897B;

	--treatment-checker__result__interaction__icon__criticality-high__color: #dc3545;
	--treatment-checker__result__interaction__icon__criticality-low__color: #ffc107;
	--treatment-checker__result__interaction__icon__criticality-undefined__color: #00897B;

	--treatment-checker__report__medication__personalized-medicine-recommendation__background: #37474F;
	--treatment-checker__report__medication__personalized-medicine-recommendation__text-color: #fefefe;
	--treatment-checker__report__medication__personalized-medicine-recommendation__border-color: #263238;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Vaccination
	// =============================================================================================

	--vaccination__card__btn-create__background: #009688;
	--vaccination__card__btn-create__text-color: #ffffff;
	--vaccination__card__btn-info__background: #212121;
	--vaccination__card__btn-info__text-color: #fafefe;

	--vaccination__info__btn-create__background: #009688;
	--vaccination__info__btn-create__text-color: #ffffff;
	--vaccination__info__btn-card__background: #212121;
	--vaccination__info__btn-card__text-color: #fafefe;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Interactive background
	// =============================================================================================

	--interactive-background__container__background: #212121;
	--interactive-background__particles__color-1: #455A64;
	--interactive-background__particles__color-2: #37474F;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Layoutless page (page as layout)
	// =============================================================================================

	--layout-page__img__logo: url('/assets/apixmed-dark/branding/dna-logo.svg');

	--layout-page__header__container__background: #212121;
	--layout-page__header__container__text-color: rgba(255, 255, 255, 1);

	--layout-page__scrollbar__background: #EFF6EE;
	--layout-page__scrollbar-thumb__background: #212121;
	--layout-page__scrollbar-track__background: #EFF6EE;
	--layout-page__scrollbar-track__box-shadow-color: rgba(0, 0, 0, 0.3);

	--layout-page__footer__background: #212121;
	--layout-page__footer__text-color: #fafefe;
	--layout-page__footer__app-info__text-color: rgba(255, 255, 255, 0.6);

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Sidebar
	// =============================================================================================

	--sidebar__scrollbar__background: #fafefe;
	--sidebar__scrollbar-thumb__background: #212121;
	--sidebar__scrollbar-track__background: #fafefe;
	--sidebar__scrollbar-track__box-shadow-color: rgba(0, 0, 0, 0.3);

	--sidebar__container__background: #424242;
	--sidebar__container__text-color: #fefefe;
	--sidebar__container__active__background: #212121;
	--sidebar__container__active__text-color: #fefefe;
	--sidebar__container__divider__color: #636363;

	--sidebar__tooltip__background: #636363;
	--sidebar__tooltip__text-color: #fefefe;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Sidenav
	// =============================================================================================

	--sidenav__container__background: #212121;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Layout
	// =============================================================================================

	--layout__content__scrollbar__background: #fafefe;
	--layout__content__scrollbar-thumb__background: #212121;
	--layout__content__scrollbar-track__background: #fafefe;
	--layout__content__scrollbar-track__box-shadow-color: rgba(0, 0, 0, 0.3);

	--layout__content__container__background: none;

	--layout__content__card__container__background: #424242;
	--layout__content__card__container__text-color: #fafefe;
	--layout__content__card__btn-submit__background: #212121;
	--layout__content__card__btn-submit__text-color: #fafefe;

	--layout__footer__background: #212121;
	--layout__footer__text-color: #FFFFFF;

	--layout__logo__text-color: #FFFFFF;

	--layout__page__container__background: #424242;
	--layout__page__container__scrollbar__background: #fafefe;
	--layout__page__container__scrollbar-thumb__background: #212121;
	--layout__page__container__scrollbar-track__background: #fafefe;
	--layout__page__container__scrollbar-track__box-shadow-color: rgba(0, 0, 0, 0.3);

	--layout__page__table-row-active__background: #636363;

	--layout__account__menu__text-color: #ffffff;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Additional non standard features
	// =============================================================================================

	// temporary solution for links in treatment checker report (for dark theme only)
	.nmn-disease-checker-result-section-container {
		a:link { color: #64B5F6; }
		a:visited { color: #9575CD; }
		a:hover { color: #ffffff; }
		a:active { color: #F06292; text-decoration:none; font-weight:normal; }
	}

	// temporary solution for links in privacy policy (for dark theme only)
	.nmn-privacy-policy {
		a:link { color: #64B5F6; }
		a:visited { color: #9575CD; }
		a:hover { color: #ffffff; }
		a:active { color: #F06292; text-decoration:none; font-weight:normal; }
	}

	// =============================================================================================
	// *********************************************************************************************
}
