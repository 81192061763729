// *************************************************************************************************
// =================================================================================================
// General numbers
// =================================================================================================

// -------------------------------------------------------------------------------------------------
// Intendations
$indentation-small: 8px;
$indentation-medium: 12px;
$indentation-large: 18px;
// -------------------------------------------------------------------------------------------------

// -------------------------------------------------------------------------------------------------
// Borders
$border-radius: 16px;
// -------------------------------------------------------------------------------------------------

// =================================================================================================
// *************************************************************************************************
