// =================================================================================================
// page info

.nmn-page-info-text-section {
    padding: $indentation-medium 0;
}

.nmn-page-info-text-section-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 900;
}

.nmn-page-info-text-section-paragraph {
    margin: #{$indentation-medium * 2} 0 0 0;
    font-size: 16px;
    line-height: 24px;
}

.nmn-page-info-text-section-list {
    margin: $indentation-medium 0;
}

// =================================================================================================

// =================================================================================================
