.nmn-single-form-page {
    height: 100%;
    background-color: $layout__page__background;
    overflow: hidden;
    place-content: center;
}

.nmn-single-form-container-wrapper {
    height: 100%;
    background: var(--layout__content__container__background) !important;
    place-content: center;
}

.nmn-single-form-container {
    margin: 0 #{$indentation-medium * 2};
}

.nmn-single-form-logo-container {
    padding: $indentation-medium;
    height: $layout__content__container__logo-img__max-height;
}

.nmn-single-form-logo-icon {
	background-image: var(--layout-page__img__logo);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	width: $layout-page__header__title__font-size;
	height: $layout-page__header__title__line-height;
	margin-right: calc(#{$layout-page__header__title__font-size} / 2);
}

.nmn-single-form-logo-text {
	font-size: $layout-page__header__title__font-size;
	line-height: $layout-page__header__title__line-height;
	color: var(--layout__logo__text-color);
}

.nmn-single-form-card-container-wrapper {
    overflow: hidden;
    height: calc(100% - #{$layout__content__container__logo-img__max-height + $indentation-medium + $indentation-medium + $indentation-medium / 3 + $indentation-medium});
    padding: #{$indentation-medium / 3} #{$indentation-medium / 3} $indentation-medium #{$indentation-medium / 3};
}

.nmn-single-form-card-container {
    height: 100%;
    overflow: auto;
    border-radius: calc(#{$border-radius} / 2);
    background: var(--layout__content__card__container__background) !important;
    color: var(--layout__content__card__container__text-color) !important;
}

.nmn-single-form-card-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 $layout__content__scrollbar-track__box-shadow-width var(--layout__content__scrollbar-track__box-shadow-color);
    box-shadow: inset 0 0 $layout__content__scrollbar-track__box-shadow-width var(--layout__content__scrollbar-track__box-shadow-color);
    background: var(--layout__content__scrollbar-track__background);
}

.nmn-single-form-card-container::-webkit-scrollbar {
    width: $layout__content__scrollbar__width;
    background: var(--layout__content__scrollbar__background);
}

.nmn-single-form-card-container::-webkit-scrollbar-thumb {
    background: var(--layout__content__scrollbar-thumb__background);
}

.nmn-single-form-page-content {}

.nmn-single-form-card-submit-btn {
    background: var(--layout__content__card__btn-submit__background) !important;
    color: var(--layout__content__card__btn-submit__text-color) !important;
}
