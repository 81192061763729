// *************************************************************************************************
// =================================================================================================

$layout__content__scrollbar__width: 8px;
$layout__content__scrollbar-track__box-shadow-width: 2px;

$layout__content__container__logo-img__max-height: 40px;

$layout__page__background: none;

$layout__footer__height: 32px;

// =================================================================================================
// *************************************************************************************************
