@import './variables/variables';
@import './theming/theming';

// old: to remove
@import './other-styles';


@import './basic-styles';
@import './shared/shared-styles';

// ================================================================================================
// ================================================================================================
// Crunches and trick overrides
// ================================================================================================

// Is used to allow hide elements (with hidden attribute) that have delined display property
[hidden] {
	display: none !important;
}

// ================================================================================================
// ================================================================================================
// Main styles
// ================================================================================================

html,
body {
    height: 100%;
}

body {
    // Do not define styles here
	// see: mat-typography-config and theming

    margin: 0;
}

.pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default !important;
}

.text-as-link {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
}

.text-as-link:hover {
    // color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}

.link-as-text {
    color: inherit;
    cursor: pointer;
    text-decoration: none;
}

.link-as-text:hover {
    cursor: pointer;
    text-decoration: none;
}

.small-text {
    font-size: smaller;
}

.centered-text {
    text-align: center;
}

.right-text {
    text-align: right;
}

.left-text {
    text-align: left;
}

.bold-text {
    font-weight: 900;
}

.italic-text {
    font-style: italic;
}

.half-opacity-text {
    opacity: 0.5;
}

.nmn-monochrome {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}

.nmn-full-width {
    width: 100%;
}

.nmn-half-width {
    width: 50%;
}

.nmn-width-90 {
    width: 90%;
}

.nmn-width-75 {
    width: 75%;
}

.nmn-width-66 {
    width: 66%;
}

.nmn-width-33 {
    width: 33%;
}

.nmn-width-25 {
    width: 25%;
}

.nmn-width-20 {
    width: 20%;
}

.nmn-full-height {
    height: 100%;
}

.inline-icon {
    margin: 0 4px;
}

.nmn-icon-default-text-height {
    font-size: 20px !important;
}

.nmn-default-icon {
    height: 24px;
    width: 24px;
}

.quarter-opacity {
    opacity: 0.25;
}

.nmn-display-none {
    display: none !important;
}

.nmn-translated-text {
    white-space: pre-wrap;
}

.nmn-checkbox-as-icon-btn {
    margin: 10px 12px 14px 12px;
}

.nmn-icon-btn-placeholder {
    width: 40px;
    height: 40px;
    display: inline-block;
}

.nmn-mat-icon-placeholder {
    width: 24px;
    height: 24px;
    display: inline-block;
}

.nmn-tooltip {
    white-space: pre-line !important;
}

.nmn-narrow-action-list {
    height: 32px !important;
}

.nmn-sticky-toolbar {
    position: sticky;
    /* For macOS/iOS Safari */
    position: -webkit-sticky;
    /* Sets the sticky toolbar to be on top */
    top: 0;
    /* Ensure that your app's content doesn't overlap the toolbar */
    z-index: 1000;
}

.nmn-tab-icon {
    padding: 0 8px 0 0;
}

.nmn-tab-icon {}

.nmn-severity-color-green {
    color: #00BFA5;
}

.nmn-severity-color-gray {
    color: #6c757d;
}

.nmn-severity-color-red {
    color: #dc3545;
}

.nmn-severity-color-yellow {
    color: #ffc107;
}

.nmn-control-help-icon {
    color: #727272
}

// ================================================================================================
// show\hide features
// ================================================================================================

// hide

.nmn-hide-xxs {
	@media (width < $breakpoint-xxs) {
		display: none !important;
	}
}

.nmn-hide-xs {
	@media ($breakpoint-xxs < width <= $breakpoint-xs) {
		display: none !important;
	}
}

.nmn-hide-sm {
	@media ($breakpoint-xs < width <= $breakpoint-sm) {
		display: none !important;
	}
}

.nmn-hide-md {
	@media ($breakpoint-sm < width <= $breakpoint-md) {
		display: none !important;
	}
}

.nmn-hide-lg {
	@media ($breakpoint-md < width <= $breakpoint-lg) {
		display: none !important;
	}
}

.nmn-hide-xlg {
	@media ($breakpoint-lg < width <= $breakpoint-xlg) {
		display: none !important;
	}
}

.nmn-hide-xxlg {
	@media ($breakpoint-xlg < width) {
		display: none !important;
	}
}

// hide gt

.nmn-hide-xxs-gt {
	@media ($breakpoint-xxs <= width) {
		display: none !important;
	}
}

.nmn-hide-xs-gt {
	@media ($breakpoint-xs <= width) {
		display: none !important;
	}
}

.nmn-hide-sm-gt {
	@media ($breakpoint-sm <= width) {
		display: none !important;
	}
}

.nmn-hide-md-gt {
	@media ($breakpoint-md <= width) {
		display: none !important;
	}
}

.nmn-hide-lg-gt {
	@media ($breakpoint-lg <= width) {
		display: none !important;
	}
}

.nmn-hide-xlg-gt {
	@media ($breakpoint-xlg <= width) {
		display: none !important;
	}
}

// hide lt

.nmn-hide-xs-lt {
	@media (width < $breakpoint-xs) {
		display: none !important;
	}
}

.nmn-hide-sm-lt {
	@media (width < $breakpoint-sm) {
		display: none !important;
	}
}

.nmn-hide-md-lt {
	@media (width < $breakpoint-md) {
		display: none !important;
	}
}

.nmn-hide-lg-lt {
	@media (width < $breakpoint-lg) {
		display: none !important;
	}
}

.nmn-hide-xlg-lt {
	@media (width < $breakpoint-xlg) {
		display: none !important;
	}
}

// ================================================================================================

// ================================================================================================
// ================================================================================================
// Angular material theme overrides
// ================================================================================================

// ================================================================================================
// Button
// ================================================================================================

.nmn-mat-icon-btn-as-btn-wrapper {
	margin: 0 16px;
}

// ================================================================================================
// Progress bar
// ================================================================================================

.mat-progress-bar-buffer {
    background-color: rgb(178, 223, 219);
}

.mat-progress-bar-fill::after {
    background-color: rgb(84, 110, 122);
}

// ================================================================================================
// Pricing mat dialog
// ================================================================================================

.nmn-dialog-pricing {
	.mat-dialog-container {
		border-radius: $border-radius;
	}
}

// ================================================================================================
// Toggle button group
// ================================================================================================

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 36px !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    padding: 0px 6px !important;
}

// ================================================================================================
// Table
// ================================================================================================

.nmn-table {
	.mat-row:not(.nmn-table-row-detalization-container) {
		height: 64px;
	}
}

// ================================================================================================
// Chip
// ================================================================================================

.mat-standard-chip {
	height: auto !important;
}

// ================================================================================================
// Stepper
// ================================================================================================

::ng-deep .nmn-treatment-checker-form, .nmn-patient-create-form {

	.mat-stepper-horizontal, .mat-stepper-vertical {
		background-color: transparent;
	}

    .mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
    .mat-vertical-stepper-header {
		@media (max-width: $breakpoint-xs) {
			padding: 24px 6px;
		}

		@media (min-width: $breakpoint-xs) {
			padding: 24px 24px;
		}
    }

	.mat-vertical-content-container {
		@media (max-width: $breakpoint-xs) {
			margin-left: 18px;
		}

		@media (min-width: $breakpoint-xs) {
			margin-left: 36px;
		}
	}

}

// ================================================================================================
// ================================================================================================
// Calendar
// ================================================================================================

.widget-calendar-highlighted-date .mat-calendar-body-cell-content {
    background: lightblue;
}

.widget-disease-status-list-item .mat-list-item-content {
    justify-content: space-between;
    align-items: baseline;
}

// ================================================================================================

// ================================================================================================
// ================================================================================================
// Shared
// ================================================================================================
.nmn-sides-small-space {
    margin-left: 4px;
    margin-right: 4px;
}

.nmn-bottom-space {
    @media (max-width: $breakpoint-xs) {
        margin-bottom: 12px !important;
    }

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        margin-bottom: 8px !important;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin-bottom: 8px !important;
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xlg) {
        margin-bottom: 12px !important;
    }

    @media (min-width: $breakpoint-xlg) {
        margin-bottom: 16px !important;
    }
}

.nmn-top-space {
    @media (max-width: $breakpoint-xs) {
        margin-top: 12px !important;
    }

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        margin-top: 8px !important;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin-top: 8px !important;
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xlg) {
        margin-top: 12px !important;
    }

    @media (min-width: $breakpoint-xlg) {
        margin-top: 16px !important;
    }
}

// ================================================================================================

// ================================================================================================
// ================================================================================================
// Pages
// ================================================================================================

// page

.nmn-page {
    height: calc(100% - #{$indentation-medium / 2} - #{$layout__footer__height});
    overflow: hidden;
    border-radius: calc(#{$border-radius} / 2);
}

// page container

.nmn-page-container {
    height: 100%;
    background: var(--layout__page__container__background);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.nmn-page-container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 2px var(--layout__page__container__scrollbar-track__box-shadow-color);
    box-shadow: inset 0 0 2px var(--layout__page__container__scrollbar-track__box-shadow-color);
    background-color: var(--layout__page__container__scrollbar-track__background);
    border-radius: 4px;
}

.nmn-page-container::-webkit-scrollbar {
    width: 4px;
    background-color: var(--layout__page__container__scrollbar__background);
}

.nmn-page-container::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: var(--layout__page__container__scrollbar-thumb__background);
}

// page header

.nmn-page-header {
    @media (max-width: $breakpoint-xs) {
        margin: 24px 24px 12px 24px;
    }

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        margin: 16px 16px 8px 16px;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin: 16px 16px 8px 16px;
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xlg) {
        margin: 24px 24px 12px 24px;
    }

    @media (min-width: $breakpoint-xlg) {
        margin: 32px 32px 16px 32px;
    }
}

// page title

.nmn-page-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    letter-spacing: normal;
    margin: 0 0 16px;
}

// page subtitle

.nmn-page-subtitle {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: normal;
    margin: 16px 0px 8px 0px;
}

// page-content

.nmn-page-content {
    @media (max-width: $breakpoint-xs) {
        margin: 12px 24px 12px 24px;
        min-height: calc(100% - (24px + 32px + 12px) - (12px + 12px) - (36px + 12px));
    }

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        margin: 8px 16px 8px 16px;
        min-height: calc(100% - (16px + 32px + 8px) - (8px + 8px) - (36px + 8px));
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin: 8px 16px 8px 16px;
        min-height: calc(100% - (16px + 32px + 8px) - (8px + 8px) - (36px + 8px));
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xlg) {
        margin: 12px 24px 12px 24px;
        min-height: calc(100% - (24px + 32px + 12px) - (12px + 12px) - (36px + 12px));
    }

    @media (min-width: $breakpoint-xlg) {
        margin: 16px 32px 16px 32px;
        min-height: calc(100% - (32px + 32px + 16px) - (16px + 16px) - (36px + 16px));
    }
}

// page footer

.nmn-page-footer {
    @media (max-width: $breakpoint-xs) {
        margin: 12px 24px 12px 24px;
    }

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        margin: 8px 16px 8px 16px;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin: 8px 16px 8px 16px;
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xlg) {
        margin: 12px 24px 12px 24px;
    }

    @media (min-width: $breakpoint-xlg) {
        margin: 16px 32px 16px 32px;
    }
}

// page button

.nmn-page-footer-btn {}

.nmn-form-btn-icon {
    vertical-align: baseline !important;
}

// ================================================================================================

// ================================================================================================
// ================================================================================================
// Table
// ================================================================================================

.nmn-table {
    width: 100%;
}

.nmn-table-container {
    margin: 0;
    min-height: calc(100% - 56px);
}

.nmn-table-container-documents {
    margin: 0;

    @media (max-width: $breakpoint-md) {
		// used to add height for document size progress bar
		min-height: calc(100% - 56px - 32px);
    }

    @media (min-width: $breakpoint-md) {
		min-height: calc(100% - 56px);
    }
}

.nmn-table-header-alt {
    border-bottom: none !important;
    padding: 0px 12px !important;
}

.nmn-table-header-alt-container {
    padding: 16px 0px 0px 0px;
}

.nmn-table-header-alt-container-element {
    margin: 4px 0px;
}

.nmn-table-header-filter-form-field-icon {
    margin: 0 8px -8px 0;
}

.nmn-table-header-item {
    margin: 0px 2px !important;
}

.nmn-table-row-container-empty {
    text-align: center;
    padding: 64px 0;
}

.nmn-table-row-detalization {
    overflow: hidden;
    display: flex;
}

.nmn-table-content-icon {
    color: #727272
}

.nmn-paginator-container {}

// TODO: material icon official google library is not supported anymore, so we should migrate to native css usage (or use other library)
// see https://developers.google.com/fonts/docs/material_symbols and search for exampls: genetics
.nmn-google-mat-icon {
	margin: 0 8px 0 0;
}

// ================================================================================================

// ================================================================================================
// ================================================================================================
// Forms
// ================================================================================================

// form container

.nmn-form {
    @media (max-width: $breakpoint-xs) {
        margin: 10px;
    }

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        margin: 10px;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin: 12px;
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xlg) {
        margin: 12px;
    }

    @media (min-width: $breakpoint-xlg) {
        margin: 16px;
    }
}

// form content

.nmn-form-content {}

// form content as section

.nmn-form-content-section {
    @media (max-width: $breakpoint-xs) {
        margin: 8px 0;
    }

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        margin: 4px 0;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin: 4px 0;
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xlg) {
        margin: 8px 0;
    }

    @media (min-width: $breakpoint-xlg) {
        margin: 12px 0;
    }
}

.nmn-form-content-section-title {
    opacity: 1;
    font-weight: 500;
    display: flex;
    box-sizing: border-box;
    align-items: center;
	color: var(--treatment-checker__result__section-title__text-color);
}

// form field

.nmn-form-field {
    @media (max-width: $breakpoint-xs) {
        margin: 4px 0;
        padding: 0;
    }

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        margin: 2px 0;
        padding: 0 10px;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin: 2px 0;
        padding: 0 12px;
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xlg) {
        margin: 2px 0;
        padding: 0 14px;
    }

    @media (min-width: $breakpoint-xlg) {
        margin: 6px 0;
        padding: 0 16px;
    }
}

.nmn-form-field-suffix-text {
    opacity: 0.5;
}

// form accordeon

.nmn-form-accordion {
    margin: 16px 0;
}

// form button

.nmn-form-btn {}

// form field button

.nmn-form-field-btn {
    margin-bottom: 1.25em !important;
}

// form control slider (slide toggle)

.nmn-form-control-slide-toggle {}

.nmn-form-divider {
    border-top-style: dashed !important;
    border-top-width: 4px !important;

    @media (max-width: $breakpoint-xs) {
        margin: 24px 0px 36px 0px !important;
    }

    @media (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
        margin: 12px 0px 24px 0px !important;
    }

    @media (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
        margin: 12px 0px 24px 0px !important;
    }

    @media (min-width: $breakpoint-md) and (max-width: $breakpoint-xlg) {
        margin: 24px 0px 36px 0px !important;
    }

    @media (min-width: $breakpoint-xlg) {
        margin: 36px 0px 48px 0px !important;
    }
}

// ================================================================================================

// view

.nmn-view-section {
    margin: 12px 0px;
    padding: 0px 8px;
}

.nmn-view-section-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    letter-spacing: normal;
}

.nmn-view-section-content {
    margin: 8px 0px 8px 8px;
}

.nmn-view-section-content-property {
    margin: 8px 0px 8px 0px;
}

.nmn-view-section-content-property-label {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: normal;
    margin: 0px 0px 0px 0px;
}

.nmn-view-section-content-property-value {
    margin: 0px 0px 0px 12px;
}

.nmn-view-section-content-property-list-value {
    margin: 12px 0px 0px 12px;
}

// ================================================================================================

// short view

.nmn-short-view-section {
    margin: 12px 0px;
}

.nmn-short-view-property {
    margin: 8px 0px;
}

// ================================================================================================

// autocomplete

.nmn-autocomplete-with-description-option {
    height: auto !important;
}

.nmn-autocomplete-with-description-option.mat-option {
    line-height: 24px !important;
}

.nmn-autocomplete-with-description-option-container {}

.nmn-autocomplete-with-description-option-text {}

.nmn-autocomplete-with-description-option-text-main {
    line-height: 48px;
}

.nmn-autocomplete-with-description-option-text-alt {
    margin-left: 12px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: smaller;
}

// extended combobox

.nmn-combobox-option-extended {
    height: auto !important;
    margin: 12px 0;
}

.nmn-combobox-option-extended.mat-option {
    line-height: 24px !important;
}

.nmn-combobox-option-extended-icon {}

.nmn-combobox-option-extended-text-main {
    line-height: 32px;
}

.nmn-combobox-option-extended-text-alt {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    font-size: smaller;
}

// move to separate module `file-icons`
.nmn-document-attachment-upload-icon {
    //margin: 24px;
}

// ================================================================================================
// custom chips
// ================================================================================================
.nmn-relations-chip-list {

    .nmn-relation-chip {}

    .nmn-relation-chip-doctor-encounter {
        background: var(--tag__chip__doctor-encounter__background) !important;
		color: var(--tag__chip__doctor-encounter__text-color) !important;
    }

    .nmn-relation-chip-document {
        background: var(--tag__chip__document__background) !important;
		color: var(--tag__chip__document__text-color) !important;
    }

    .nmn-relation-chip-health-issue {
        background: var(--tag__chip__health-issue__background) !important;
		color: var(--tag__chip__health-issue__text-color) !important;
    }

    .nmn-relation-chip-taken-medication {
        background: var(--tag__chip__taken-medication__background) !important;
		color: var(--tag__chip__taken-medication__text-color) !important;
    }

}

.nmn-tags-chip-list {

    .nmn-tag-chip {}

    .nmn-tag-chip-disease {
        background: var(--tag__chip__disease__background) !important;
		color: var(--tag__chip__disease__text-color) !important;
    }

    .nmn-tag-chip-medication {
        background: var(--tag__chip__medication__background) !important;
		color: var(--tag__chip__medication__text-color) !important;
    }

    .nmn-tag-chip-symptom {
        background: var(--tag__chip__symptom__background) !important;
		color: var(--tag__chip__symptom__text-color) !important;
    }

    .nmn-tag-chip-disabled {
        background: var(--tag__chip__serious-disease__disabled__background) !important;
		color: var(--tag__chip__serious-disease__disabled__text-color) !important;
    }

}

// ================================================================================================

.nmn-facebook-btn {
	background: var(--vendor__facebook__btn__background) !important;
	color: var(--vendor__facebook__btn__text-color) !important;

	.nmn-inline-facebook-icon{
		margin: 0 4px;
	}
}

// ================================================================================================
// Icon
// ================================================================================================

.nmn-treatment-checker-result-icon {
	color: var(--treatment-checker__result__icon__color) !important;
}

.nmn-expansion-panel-icon {
	width: 24px;
	height: 24px;
	margin: 0 16px 0 0;
}

.nmn-form-anamnesis-icon {
	color: var(--form-anamnesis__icon__text-color) !important;
}

// ================================================================================================
// List
// ================================================================================================

.nmn-list {
	display: flex;
	flex-wrap: wrap;
	margin: 8px 0;
}

.nmn-list-item {
	width: 100%;
	display: flex;
	flex-shrink: 0;
	margin: 8px 16px;
}

.nmn-list-item-icon-container {
	display: flex;
	flex: none;
	align-items: center;
	justify-content: center;
}

.nmn-list-item-icon {
	display: flex;
	align-items: center;
	margin: 0 16px 0 0;
}

.nmn-list-item-text-container {
	flex: 1;
}

.nmn-list-item-text {
	width: 100%;
}

// ================================================================================================
// Combobox
// ================================================================================================

.nmn-combobox-option-text-main {
	@media (max-width: $breakpoint-xxs) {
		font-size: 12px;
		line-height: 18px;
	}

	@media (min-width: $breakpoint-xxs) {
		font-size: 16px;
		line-height: 24px;
	}
}

.nmn-combobox-option-text-alt {
	@media (max-width: $breakpoint-xxs) {
		font-size: 10px;
		line-height: 16px;
	}

	@media (min-width: $breakpoint-xxs) {
		font-size: 12px;
		line-height: 18px;
	}
}

// ================================================================================================
// Global application exclusions
// ================================================================================================

// exclusion for premium subscription type features view on lg screen
// is used to align headers for basic and premium
// see nmn-pricing-section-item-container-header.margin-top
// see diff nmn-pricing-section-item-container.height and nmn-pricing-section-item-container-lg.height
.nmn-subscription-type-view-premium.nmn-pricing-section-item-container-lg {
	.nmn-pricing-section-item-container-header {
		margin-top: calc(16px + 20px);
	}
}

// ================================================================================================


// ================================================================================================
// Flex custom styles
// ================================================================================================

.nmn-flex {
	display: flex;
	box-sizing: border-box;
}

.nmn-flex-align-center {
	display: flex;
	box-sizing: border-box;
	align-items: center;
}

.nmn-flex-justify-center {
	display: flex;
	box-sizing: border-box;
	justify-content: center;
}

.nmn-flex-center {
	display: flex;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
}

.nmn-flex-start {
	display: flex;
	box-sizing: border-box;
	align-items: flex-start;
	justify-content: flex-start;
}

.nmn-flex-start-center {
	display: flex;
	box-sizing: border-box;
	align-items: flex-start;
	justify-content: center;
}

.nmn-flex-center-start {
	display: flex;
	box-sizing: border-box;
	align-items: center;
	justify-content: flex-start;
}

.nmn-flex-wrap {
	display: flex;
	box-sizing: border-box;
	flex-wrap: wrap;
}

.nmn-flex-nowrap {
	display: flex;
	box-sizing: border-box;
	flex-wrap: nowrap;
}

.nmn-flex-wrapped-row {
	display: flex;
	box-sizing: border-box;
	flex-direction: row;
	flex-wrap: wrap;
}

.nmn-flex-wrapped-row-reverse {
	display: flex;
	box-sizing: border-box;
	flex-direction: row-reverse;
	flex-wrap: wrap;
}

.nmn-flex-text-truncated {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// ================================================================================================

.select-disabled {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.select-enabled {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -o-user-select: text;
    user-select: text;
}
