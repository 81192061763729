.apixmed-light {

	// *********************************************************************************************
	// =============================================================================================
	// Shared
	// =============================================================================================

	--gdpr-panel__background: rgba(84, 110, 122, 0.8);
	--gdpr-panel__text-color: rgba(255, 255, 255, 1);
	--gdpr-panel__btn-accept__background: rgba(255, 255, 255, 1);
	--gdpr-panel__btn-accept__text-color: rgba(84, 110, 122, 1);

	--tag__chip__disease__background:#fb898d;
	--tag__chip__disease__text-color:#000;
	--tag__chip__medication__background:#77cff9;
	--tag__chip__medication__text-color:#000;
	--tag__chip__symptom__background:#fcb0b3;
	--tag__chip__symptom__text-color:#000;
	--tag__chip__doctor-encounter__background: #eacaec;
	--tag__chip__doctor-encounter__text-color: #000;
	--tag__chip__document__background: #ffecb3;
	--tag__chip__document__text-color: #000;
	--tag__chip__health-issue__background: #fdc4c6;
	--tag__chip__health-issue__text-color: #000;
	--tag__chip__taken-medication__background: #b3e5fc;
	--tag__chip__taken-medication__text-color: #000;
	--tag__chip__serious-disease__disabled__background: #E0E0E0;
	--tag__chip__serious-disease__disabled__text-color: #000;

	--form-anamnesis__icon__text-color: #000;

	--gif__treatment-checker__icon: url('/assets/apixmed-light/shared/genetic-engineering.gif');

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Vendors
	// =============================================================================================

	--vendor__facebook__btn__background: rgb(26, 119, 242);
	--vendor__facebook__btn__text-color: rgb(255, 255, 255);

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Accounts
	// =============================================================================================

	--accounts-layout__scrollbar__background: #fafefe;
	--accounts-layout__scrollbar-thumb__background: rgba(84, 110, 122, 1);
	--accounts-layout__scrollbar-track__background: #fafefe;
	--accounts-layout__scrollbar-track__box-shadow__color: rgba(0, 0, 0, 0.3);

	--accounts-layout__greeting-container__background: none;
	--accounts-layout__greeting-title__text-color: rgba(84, 110, 122, 1);
	--accounts-layout__greeting-description__text-color: rgba(84, 110, 122, 1);

	--accounts-layout__main-container__background: rgba(84, 110, 122, 1);

	--accounts-layout__main-card__container__background: #fafefe;
	--accounts-layout__main-card__container__text-color: rgba(84, 110, 122, 1);
	--accounts-layout__main-card__submit-btn__background: rgba(0, 150, 136, 1);
	--accounts-layout__main-card__submit-btn__text-color: #fafefe;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Genetic card
	// =============================================================================================

	--genetic-tests__card__btn-create__background: #009688;
	--genetic-tests__card__btn-create__text-color: #ffffff;

	--genetic-tests__card__btn-info__background: rgba(84, 110, 122, 1);
	--genetic-tests__card__btn-info__text-color: #fafefe;

	--genetic-tests__card__genetic-file-upload__btn-navigate-genetic-card__background:#009688;
	--genetic-tests__card__genetic-file-upload__btn-navigate-genetic-card__text-color:#ffffff;

	--genetic-tests__card__genetic-file-upload__btn-initiate-new-upload__background: #009688;
	--genetic-tests__card__genetic-file-upload__btn-initiate-new-upload__text-color: #ffffff;

	--genetic-tests__info__btn-create__background: #009688;
	--genetic-tests__info__btn-create__text-color: #ffffff;

	--genetic-tests__info__btn-card__background: rgba(84, 110, 122, 1);
	--genetic-tests__info__btn-card__text-color: #fafefe;


	--genetic-tests__creation-type-default__background-color: #00BFA5;
	--genetic-tests__creation-type-imported__background-color: #274983;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Home page B2C
	// =============================================================================================

	--home-page-b2c__img__logo: url('/assets/apixmed-light/branding/dna-logo.svg');

	--home-page-b2c__scrollbar__background: rgba(255, 255, 255, 0.5);
	--home-page-b2c__scrollbar-thumb__background: rgba(168, 168, 168, 1);
	--home-page-b2c__scrollbar-track__background: rgba(228, 228, 228, 1);
	--home-page-b2c__scrollbar-track__border-color: rgba(168, 168, 168, 1);

	--home-page-b2c__header__background: rgba(84, 110, 122, 1);
	--home-page-b2c__header__text-color: rgba(255, 255, 255, 1);

	--home-page-b2c__body__background: conic-gradient(from 90deg at -10% 100%, rgba(241, 241, 241, 1) 0deg, rgba(241, 241, 241, 1) 90deg, rgba(254, 254, 254, 1));
	--home-page-b2c__body__text-color: rgba(0, 0, 0, 1);

	--home-page-b2c__footer__background: linear-gradient(0deg, rgba(254, 254, 254, 1), rgba(241, 241, 241, 1));
	--home-page-b2c__footer__text-color: rgba(0, 0, 0, 1);
	--home-page-b2c__footer__social-btn-icon__color: #424242;

	--home-page-b2c__text-highlighted__color: rgba(2, 147, 143, 1);
	--home-page-b2c__border__color: none;

	--home-page-b2c__img__dna: url('/assets/apixmed-light/modules/home/dna.svg');

	--home-page-b2c__medical-card-btn__background: linear-gradient(90deg, rgba(0, 121, 107, 1), rgba(0, 105, 92, 1));
	--home-page-b2c__medical-card-btn__text__color: rgba(250, 250, 250, 1);
	--home-page-b2c__img__medical-grid: url('/assets/apixmed-light/modules/home/medical-grid.svg');

	--home-page-b2c__treatment-checker-btn__background: linear-gradient(90deg, rgba(255, 78, 74, 1), rgba(255, 58, 106, 1));
	--home-page-b2c__treatment-checker-btn__text-color: rgba(250, 250, 250, 1);
	--home-page-b2c__treatment-checker__stepper__node1__color: rgba(0, 191, 255, 1);
	--home-page-b2c__treatment-checker__stepper__node2__color: rgba(0, 255, 229, 1);
	--home-page-b2c__treatment-checker__stepper__node3__color: rgba(16, 255, 159, 1);
	--home-page-b2c__treatment-checker__stepper__node4__color: rgba(119, 255, 0, 1);
	--home-page-b2c__img__treatment-checker__transition-1-to-2: url('/assets/apixmed-light/modules/home/treatment-checker-treatment.png');
	--home-page-b2c__img__treatment-checker__transition-2-to-3: url('/assets/apixmed-light/modules/home/treatment-checker-lifestyle.png');
	--home-page-b2c__img__treatment-checker__transition-3-to-4: url('/assets/apixmed-light/modules/home/treatment-checker-anamnesis.png');

	--home-page-b2c__img__microsoft-logo: url('/assets/apixmed-light/modules/home/microsoft.svg');
	--home-page-b2c__img__eit-logo: url('/assets/apixmed-light/modules/home/eit.png');
	--home-page-b2c__img__intersystems-logo: url('/assets/apixmed-light/modules/home/intersystems.svg');
	--home-page-b2c__img__portmone-label: url('/assets/apixmed-light/modules/home/portmone-label.svg');

	--home-page-b2c__app-info__text-color: #424242;

	--home-page-b2c__side-panel-left__btn__color: #424242;
	--home-page-b2c__side-panel-right__btn__color: #424242;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Pricing
	// =============================================================================================

	--pricing__plan-feature__available__icon__color: rgb(25, 151, 153);
	--pricing__plan-feature__available__text-color: rgb(25, 151, 153);
	--pricing__plan-feature__not-available__icon__color: #5B5555;
	--pricing__plan-feature__not-available__text-color: #5B5555;

	--pricing__plan__container__background-color: #FFFFFF;
	--pricing__plan__container__text-color: #1c2d35;

	--pricing__plan__premium__header__text-color: #279EA0;
	--pricing__plan__basic__header__text-color: #5B5555;

	--pricing__payment-plan__general__background: #FFFFFF;
	--pricing__payment-plan__general__text-color: #005570;
	--pricing__payment-plan__non-highlight__color: #5B5555;

	--pricing__payment-plan__center__border-vertical: thick solid #A6ABBD;

	--pricing__payment-plan__btn-annual__background: rgb(56, 143, 144);
	--pricing__payment-plan__btn-annual__text-color: #FFFFFF;

	--pricing__payment-plan__btn-semiannual__background: rgb(56, 143, 144, 0.6);
	--pricing__payment-plan__btn-semiannual__text-color: #005570;

	--pricing__payment-plan__btn-monthly__background: rgb(56, 143, 144, 0.2);
	--pricing__payment-plan__btn-monthly__text-color: #005570;

	--pricing__payment-plan__btn-corporate__background: #FFFFFF;
	--pricing__payment-plan__btn-corporate__text-color: #005570;

	--pricing__plan__premium__set-dialog__btn-confirm__background: #FFFFFF;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Privacy policy
	// =============================================================================================

	--privacy-policy__container__background: #fafefe;
	--privacy-policy__text-color: rgba(84, 110, 122, 1);

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Treatment checker
	// =============================================================================================

	--treatment-checker__patient__form__description__icon__color: #2196F3;
	--treatment-checker__patient__form__redirect__icon__color: #FF9800;

	--treatment-checker__home__to-form__btn__background: rgba(0, 150, 136, 1);
	--treatment-checker__home__to-form__btn__text-color: #fafefe;

	--treatment-checker__btn-analyze__background: rgba(0, 150, 136, 1);
	--treatment-checker__btn-analyze__text-color: #fafefe;

	--treatment-checker__btn-export__background: rgba(84, 110, 122, 1);
	--treatment-checker__btn-export__text-color: #fafefe;

	--treatment-checker__container__background: #fafefe;
	--treatment-checker__container__text-color: #000;

	--treatment-checker__card__background: #fefefe;
	--treatment-checker__card__text-color: #1c2d35;

	--treatment-checker__result__section-title__text-color: #000;
	--treatment-checker__result__icon__color: #05645D;

	--treatment-checker__result__interaction__icon__criticality-high__color: #dc3545;
	--treatment-checker__result__interaction__icon__criticality-low__color: #ffc107;
	--treatment-checker__result__interaction__icon__criticality-undefined__color: #05645D;

	--treatment-checker__report__medication__personalized-medicine-recommendation__background: #d1e7dd;
	--treatment-checker__report__medication__personalized-medicine-recommendation__text-color: #0f5132;
	--treatment-checker__report__medication__personalized-medicine-recommendation__border-color: #badbcc;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Vaccination
	// =============================================================================================

	--vaccination__card__btn-create__background: #009688;
	--vaccination__card__btn-create__text-color: #ffffff;
	--vaccination__card__btn-info__background: rgba(84, 110, 122, 1);
	--vaccination__card__btn-info__text-color: #fafefe;

	--vaccination__info__btn-create__background: #009688;
	--vaccination__info__btn-create__text-color: #ffffff;
	--vaccination__info__btn-card__background: rgba(84, 110, 122, 1);
	--vaccination__info__btn-card__text-color: #fafefe;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Interactive background
	// =============================================================================================

	--interactive-background__container__background: #fafefe;
	--interactive-background__particles__color-1: #a9eae3;
	--interactive-background__particles__color-2: #95e4dd;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Layoutless page (page as layout)
	// =============================================================================================

	--layout-page__img__logo: url('/assets/apixmed-light/branding/dna-logo.svg');

	--layout-page__header__container__background: rgba(84, 110, 122, 1);
	--layout-page__header__container__text-color: rgba(255, 255, 255, 1);

	--layout-page__scrollbar__background: #EFF6EE;
	--layout-page__scrollbar-thumb__background: rgba(84, 110, 122, 1);
	--layout-page__scrollbar-track__background: #EFF6EE;
	--layout-page__scrollbar-track__box-shadow-color: rgba(0, 0, 0, 0.3);

	--layout-page__footer__background: rgba(84, 110, 122, 1);
	--layout-page__footer__text-color: #fafefe;
	--layout-page__footer__app-info__text-color: rgba(255, 255, 255, 0.6);

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Sidebar
	// =============================================================================================

	--sidebar__scrollbar__background: #fafefe;
	--sidebar__scrollbar-thumb__background: rgba(84, 110, 122, 1);
	--sidebar__scrollbar-track__background: #fafefe;
	--sidebar__scrollbar-track__box-shadow-color: rgba(0, 0, 0, 0.3);

	--sidebar__container__background: #fefefe;
	--sidebar__container__text-color: rgba(84, 110, 122, 1);
	--sidebar__container__active__background: rgba(0, 150, 136, 0.1);
	--sidebar__container__active__text-color: rgba(0, 150, 136, 1);
	--sidebar__container__divider__color: rgba(84, 110, 122, 0.3);

	--sidebar__tooltip__background: rgba(0, 150, 136, 0.8);
	--sidebar__tooltip__text-color: #fefefe;

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Sidenav
	// =============================================================================================

	--sidenav__container__background: rgba(84, 110, 122, 1);

	// =============================================================================================
	// *********************************************************************************************

	// *********************************************************************************************
	// =============================================================================================
	// Layout
	// =============================================================================================

	--layout__content__scrollbar__background: #fafefe;
	--layout__content__scrollbar-thumb__background: rgba(84, 110, 122, 1);
	--layout__content__scrollbar-track__background: #fafefe;
	--layout__content__scrollbar-track__box-shadow-color: rgba(0, 0, 0, 0.3);

	--layout__content__container__background: rgba(84, 110, 122, 1);

	--layout__content__card__container__background: #fafefe;
	--layout__content__card__container__text-color: rgba(84, 110, 122, 1);
	--layout__content__card__btn-submit__background: rgba(84, 110, 122, 1);
	--layout__content__card__btn-submit__text-color: #fafefe;

	--layout__footer__background: rgba(84, 110, 122, 1);
	--layout__footer__text-color: #FFFFFF;

	--layout__logo__text-color: #FFFFFF;

	--layout__page__container__background: #fefefe;
	--layout__page__container__scrollbar__background: #fff;
	--layout__page__container__scrollbar-thumb__background: rgba(84, 110, 122, 1);
	--layout__page__container__scrollbar-track__background: #FBFFF1;
	--layout__page__container__scrollbar-track__box-shadow-color: rgba(84, 110, 122, 1);

	--layout__page__table-row-active__background: whitesmoke;

	--layout__account__menu__text-color: #000;

	// =============================================================================================
	// *********************************************************************************************
}
