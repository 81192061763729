.flex {
    display: flex;
}

.flex-none {
    flex: none;
}

.flex-wrap {
	flex-wrap: wrap;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.flex-column {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.justify-center {
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.position-fixed {
    position: fixed;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: start;
}

.display-block {
	display: block;
}

.width-full {
    width: 100%;
}

.width-half {
    width: 50%;
}

.height-full {
    height: 100%;
}

// ------------------------------------------------------------------------------------------------
// flex grow

.nmn-flex-grow-1 {
	flex-grow: 1;
}

.nmn-flex-grow-2 {
	flex-grow: 2;
}

.nmn-flex-grow-3 {
	flex-grow: 3;
}

.nmn-flex-grow-4 {
	flex-grow: 4;
}

.nmn-flex-grow-5 {
	flex-grow: 5;
}

// ------------------------------------------------------------------------------------------------
